<template>
  <v-container>
    <v-row v-if='campaignResourcesAvailable.length > 0'>
      <v-col cols="12">
        <span class="text-h2">Campaign</span>
      </v-col>
      <v-col
        v-for="(resourceAvailable, index) in campaignResourcesAvailable"
        :key="'campaign_'+campaignId+'_'+selectedBurst.id+'_'+selectedCampaignBurstScreen.id+'_'+index"
        cols="4"
      >
        <resource :resource="resourceAvailable" :campaignId="campaignId" :campaignBurstId="selectedBurst.id" :campaignBurstScreenId="selectedCampaignBurstScreen.id" @refresh="onRefreshChild"/>
      </v-col>
    </v-row>
    <v-row v-if='burstResourcesAvailable.length > 0'>
      <v-col cols="12">
        <span class="text-h2">Burst</span>
      </v-col>
      <v-col
        v-for="(resourceAvailable, index) in burstResourcesAvailable"
        :key="'burst_'+campaignId+'_'+selectedBurst.id+'_'+selectedCampaignBurstScreen.id+'_'+index"
        cols="4"
      >
        <resource :resource="resourceAvailable" :campaignId="campaignId" :campaignBurstId="selectedBurst.id" :campaignBurstScreenId="selectedCampaignBurstScreen.id" @refresh="onRefreshChild"/>
      </v-col>
    </v-row>
    <v-row v-if='screenResourcesAvailable.length > 0'>
      <v-col cols="12">
        <span class="text-h2">Screen</span>
      </v-col>
      <v-col
        v-for="(resourceAvailable, index) in screenResourcesAvailable"
        :key="'screen_'+campaignId+'_'+selectedBurst.id+'_'+selectedCampaignBurstScreen.id+'_'+index"
        cols="4"
        :class="resourceAvailable.resourceType.name.toLowerCase()=='custom javascript'?'col-12':'col-4'"
      >
        <resource :resource="resourceAvailable" :campaignId="campaignId" :campaignBurstId="selectedBurst.id" :campaignBurstScreenId="selectedCampaignBurstScreen.id" @refresh="onRefreshChild"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import CampaignController from '@/services/controllers/Campaign'
  import Resource from '@/components/artwork/Resource'
  
  export default {
    props: ['mode', 'selectedBurst', 'selectedScreen', 'selectedCampaignBurstScreen'],

    components: {
      Resource
    },

    data: () => ({
      campaignId : Number,
      screenResourcesAvailable: [],
      burstResourcesAvailable: [],
      campaignResourcesAvailable: [],
      restrictedItems: [{
        mode: 'resources',
        level: 'screen',
        shortname: 'overlays'
      }]
    }),

    created () {
      this.campaignId = parseInt(this.$route.query.cid);
      this.getResourcePack()
    },

    watch: {
      selectedCampaignBurstScreen: function (newVal, oldVal) {
        if(newVal.id != oldVal.id)
        {
          this.campaignId = parseInt(this.$route.query.cid);
          this.getResourcePack()
        }
      },
    },

    computed: {
       // Permissions
        isRestrictedResourcesView() {
            return this.$store.state.Permissions.resourcesRestrictedView && !this.$store.state.Permissions.resourcesView
        },

    },

    methods: {
      onRefreshChild (value) {
          this.getResourcePack()
      },
      shouldShowResource(resource)
      {
        // Is the user in restricted mode?
        let restricted = false;
        if (this.isRestrictedResourcesView) {
          // If the resource is restricted, it should not be shown
          console.log(resource);
          restricted = this.restrictedItems.some((item) => item.mode == this.mode && item.level == resource.resourceType.level && item.shortname == resource.resourceType.shortName)
          console.log(restricted);
        }
        // If the resource should not be visible, it should not be shown
        return !restricted && (!resource.resourceId || resource.visible) && ((resource.resourceType.type == 'media' && this.mode == "resources") || (resource.resourceType.type != 'media' && this.mode == "settings"));
      },
      getResourcePack () {
        CampaignController.getScreenResources(this.selectedCampaignBurstScreen.id)
          .then((res) => {
            this.screenResourcesAvailable = res.data.filter((resource) => this.shouldShowResource(resource))
          })
          .catch((err) => {
          })

        CampaignController.getBurstResources(this.selectedBurst.id)
          .then((res) => {
            this.burstResourcesAvailable = res.data.filter((resource) => this.shouldShowResource(resource))
          })
          .catch((err) => {
          })
        CampaignController.getCampaignResources(this.$route.query.cid)
          .then((res) => {
            this.campaignResourcesAvailable = res.data.filter((resource) => this.shouldShowResource(resource))
          })
          .catch((err) => {
          })
      },
    },
  }
</script>
